import React, { memo, useState, useCallback } from 'react';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import { EMPTY_VALUE } from 'constants/common';

import { useStyles } from './styles';
import data from './metoc_statistics.json';

/**
 * StatisticsContainer handle switch between statisticsPage & StatisticsDetails
 */
const StatisticsContainer = () => {
  const [isFaqHomePage, setIsFaqHomePage] = useState(true);
  const [content, setContent] = useState(EMPTY_VALUE);
  const handleStatisticClick = useCallback(
    (statistic) => {
      setContent(statistic);
      setIsFaqHomePage(false);
    },
    [setContent, setIsFaqHomePage]
  );
  const handleBackFaqClick = useCallback(() => {
    setContent(EMPTY_VALUE);
    setIsFaqHomePage(true);
  }, [setContent, setIsFaqHomePage]);

  if (isFaqHomePage) {
    return <StatisticsPage handleClick={handleStatisticClick} />;
  }
  return (
    <>
      <StatisticsPage handleClick={handleStatisticClick} />;
      <StatisticsDetailsPage
        content={content}
        handleClick={handleBackFaqClick}
      />
      ;
    </>
  );
};

/**
 * StatisticsPage component
 * @param handleClick - click user event
 */
const StatisticsPage = ({ handleClick }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.statsContainer}>
        <h5 className={classes.statsTitle}>Example Statistic</h5>

        <div className={classes.statsTextContainer}>
          <p className={classes.statsText}>Select a graph</p>
          <p>to learn more :</p>
        </div>

        <div className={classes.flexContainer}>
          {data.map(
            (statistic) =>
              statistic.display && (
                <div
                  role="button"
                  tabIndex={0}
                  key={statistic.name}
                  className={classes.statsSubContainer}
                  onClick={() => handleClick(statistic)}
                >
                  <h3 className={classes.statsSubTitle}>{statistic.name}</h3>
                  <div className={classes.imgContainer}>
                    <img
                      src={statistic.imgPath}
                      className={classes.img}
                      alt="img"
                    />
                  </div>
                </div>
              )
          )}
        </div>
      </div>
    </>
  );
};

/**
 * StatisticsDetailsPage component
 * @param content - statistic content
 * @param handleClick - click user event
 */
const StatisticsDetailsPage = ({ content, handleClick }) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.popupContainer}>
        <button
          type="button"
          className={classes.detailsbutton}
          onClick={handleClick}
        >
          <ArrowBackIos /> BACK TO STATISTICS
        </button>

        <div className={classes.detailsContainer}>
          <div className={classes.imgPopupContainer}>
            <img src={content.imgPath} className={classes.img} alt="img" />
          </div>

          <div className={classes.contentContainer}>
            <h3 className={classes.detailssection}>Example statistics</h3>
            <h1 className={classes.detailstitle}>{content.name}</h1>
            <div
              className={classes.detailscontent}
              dangerouslySetInnerHTML={{ __html: content.content }}
            />
          </div>
        </div>

        <div className={classes.imgHidden}>
          <img src={content.imgPath} className={classes.img} alt="img" />
        </div>
      </div>
      <div
        role="button"
        tabIndex={0}
        className={classes.overlay}
        onClick={handleClick}
      ></div>
    </>
  );
};

export default memo(StatisticsContainer);
