import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useMemo } from 'react';
import get from 'lodash/get';

import { useForm } from 'hooks/useForm';

import { useStyles } from './styles';
import { validationRules } from './validationRules';

/**
 * InviteCollaboratorForm component
 * Displays add a collaborator form
 * @param {String} email - collaborator email
 * @param {Boolean} isStaff - grant manager role
 * @param {Function} onSuccess -  handler for adding a collaborator
 * @param {Object} registerError - error from store
 */
const InviteCollaboratorForm = ({
  email,
  isStaff,
  onSuccess,
  registerError,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const initialValues = useMemo(
    () => ({
      email,
      isStaff,
    }),
    [email, isStaff]
  );
  const { setField, handleSubmit, handleBlur, values, errors } = useForm({
    initialValues,
    validationRules,
    onSuccess,
  });
  const emailError =
    get(errors, 'email', false) || get(registerError, ['email', 0], false);

  const checkboxHandler = useCallback(
    ({ target }) => {
      setField('isStaff', target.checked);
    },
    [setField]
  );

  return (
    <form>
      <div className={classes.row}>
        <Typography variant="body1" className={classes.fieldName}>
          {t('projects.invite.labels.email')}
        </Typography>
        <div className={classes.fullWidth}>
          <TextField
            fullWidth
            required
            name="email"
            size="medium"
            defaultValue={values.email}
            onBlur={handleBlur}
            variant="outlined"
          />
          <FormHelperText className={classes.helperText} error>
            {emailError}
          </FormHelperText>
        </div>
      </div>
      <FormControlLabel
        className={classes.checkboxRow}
        control={
          <Checkbox
            name="isStaff"
            value={values.isStaff}
            onChange={checkboxHandler}
            color="primary"
          />
        }
        label={t('projects.invite.roleLabel')}
      />
      {!!errors.isStaff && (
        <FormHelperText error>{errors.isStaff}</FormHelperText>
      )}
      <Typography gutterBottom variant="body2" className={classes.formText}>
        {t('projects.invite.roleText')}&nbsp;
      </Typography>
      <div className={classes.modalButton}>
        <Button
          onClick={handleSubmit}
          className={classes.sendButton}
          data-submit
        >
          {t('buttons.send')}
        </Button>
      </div>
    </form>
  );
};

InviteCollaboratorForm.propTypes = {
  email: PropTypes.string,
  isStaff: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  registerError: PropTypes.object,
};

InviteCollaboratorForm.defaultProps = {
  email: '',
  isStaff: false,
  registerError: {},
};

export default React.memo(InviteCollaboratorForm);
