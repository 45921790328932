import React, { memo, useCallback, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { selectProjectById } from 'ducks/projects/selectors';
import { Loader } from 'components/common/Loader';
import PropertiesList from 'components/points/PropertiesList';
import { STATS_ID_DELIMITER } from 'constants/projects';
import { containsOneOf } from 'helpers/string';
import { READY_GRAPH_TYPES } from 'constants/graphsConfig';
import { ASK_SPECIFIC_POINT_MODAL } from 'constants/modals';
import { useModal } from 'hooks/useModal';
import Spacer from 'components/common/Spacer';
import { trackAnalyzePointSelection } from 'ducks/trackers/actions/workzone';

import { useStyles } from './styles';

/**
 * Project analyze page component
 * displays project statistics
 */
const ProjectAnalyze = ({
  points,
  fields,
  statisticTypes,
  variables,
  shortVariables,
  changeField,
  changeType,
  changeVariables,
  parameters,
  onSelectMarker,
  selectedMarkerId,
  statsIsLoading,
  clearSelection,
  displayStatistics,
  variablesIdName,
  statisticsIdName,
  fieldsIdName,
  displayButtonIdName,
  canRun,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { selectedField, selectedVariable, selectedType } = parameters;
  const [displayFirstContent, setDisplayFirstContent] = useState(true);
  const lastSectionRef = useRef(null);

  const { id: projectId } = useParams();
  const project = useSelector((state) => selectProjectById(state, projectId));
  const isTrial = project.offerInstance.isTrial;

  const v = canRun ? shortVariables : variables;
  const selectedByNameVariable = v.find(
    (variable) => variable.name === selectedVariable.name
  );

  const replaceUnderscoreInt = (inputString, id) => {
    const pattern = /_(\d+)/g;
    const replacedString = inputString.replace(pattern, `_${id}`);
    return replacedString;
  };

  const statsIdsToRequest = selectedByNameVariable
    ? selectedByNameVariable.id.join(STATS_ID_DELIMITER)
    : '';

  const statsIdsToRequest2 = replaceUnderscoreInt(
    statsIdsToRequest,
    selectedMarkerId
  );

  const isGraphAvailable = containsOneOf(
    statsIdsToRequest2,
    READY_GRAPH_TYPES.map((type) => `-${type}_`)
  );

  const openContactUsModale = useCallback(() => {
    openModal(ASK_SPECIFIC_POINT_MODAL);
  }, [openModal]);

  const displayNextContent = useCallback(() => {
    setDisplayFirstContent(false);
  }, [setDisplayFirstContent]);

  const displayPreviousContent = useCallback(() => {
    setDisplayFirstContent(true);
  }, [openModal]);

  const handlePointSelection = (e) => {
    onSelectMarker(e);
    dispatch(trackAnalyzePointSelection('Point list'));
    setTimeout(() => {
      handleScrollToLast();
    }, 100);
  };

  const handleScrollToLast = () => {
    if (lastSectionRef.current) {
      lastSectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {isTrial && (
        <div className={classes.container}>
          <div className={classes.content}>
            <p>
              You are in the <strong className={classes.bold}>demo mode</strong>{' '}
              of Metocean Analytics which gives access to statistics related to{' '}
              <strong className={classes.bold}>one selected point</strong>{' '}
              within the bay of Biscay.
            </p>

            <p>
              <strong className={classes.bold}>
                To view an analysis of the location of your choice
              </strong>
              , please contact us below.
            </p>

            <p>
              To view additional guidance on viewing statistics at selected
              point, click on <strong className={classes.bold}>Continue</strong>
            </p>
          </div>

          {!displayFirstContent && (
            <div className={classes.content}>
              <p>
                To{' '}
                <strong className={classes.bold}>visualize statistics</strong>{' '}
                at the predefined location, click on the{' '}
                <strong className={classes.bold}>green marker</strong> on the
                map to select the{' '}
                <strong className={classes.bold}>location</strong>.
              </p>

              <p>
                Then <strong className={classes.bold}>scroll down</strong> to{' '}
                <strong className={classes.bold}>select</strong> which{' '}
                <strong className={classes.bold}>field</strong> you are
                interested in, the{' '}
                <strong className={classes.bold}>type of statistic</strong>, and
                the <strong className={classes.bold}>variables</strong> you
                would like to analyze, then click on{' '}
                <strong className={classes.bold}>Display Statistics</strong> to
                visualize the results.
              </p>

              <p>
                <strong className={classes.bold}>
                  To view an analysis at the location of your choice
                </strong>
                , please contact us below.
              </p>
            </div>
          )}

          <div className={classes.btnContainer}>
            <Button
              variant="contained"
              className={classes.fillButton}
              classes={{ label: classes.fillLabel }}
              onClick={openContactUsModale}
            >
              Contact us
            </Button>

            {displayFirstContent && (
              <Button
                variant="contained"
                className={classes.floatingButton}
                classes={{ label: classes.label }}
                onClick={displayNextContent}
              >
                Continue
              </Button>
            )}

            {!displayFirstContent && (
              <Button
                variant="contained"
                className={classes.floatingButton}
                classes={{ label: classes.label }}
                onClick={displayPreviousContent}
              >
                Back
              </Button>
            )}
          </div>
        </div>
      )}
      {statsIsLoading && (
        <div className={classes.loader}>
          <Typography>{t('projects.loading')}</Typography>
          <Loader size={30} />
        </div>
      )}
      <div id="lastSection" ref={lastSectionRef}>
        <PropertiesList
          items={points}
          title={t('points.pointSelection')}
          onItemClick={handlePointSelection}
          selectedId={selectedMarkerId}
          iconLabel="point_selection"
          sort
        />
        <PropertiesList
          items={fields}
          title={t('points.fieldTitle')}
          onItemClick={changeField}
          selectedId={selectedField.id}
          idName={fieldsIdName}
          iconLabel="field"
        />
        <PropertiesList
          items={statisticTypes}
          title={t('points.statisticTitle')}
          onItemClick={changeType}
          selectedId={selectedType.id}
          idName={statisticsIdName}
          iconLabel="statistic"
        />
        <PropertiesList
          items={canRun ? shortVariables : variables}
          title={t('points.variablesTitle')}
          onItemClick={changeVariables}
          selectedId={selectedVariable.name}
          idName={variablesIdName}
          iconLabel="variable"
        />
        <Button
          disabled={
            selectedVariable.name !== undefined && canRun
              ? !canRun
              : !isGraphAvailable
          }
          data-stats-ids={statsIdsToRequest2}
          data-stat-type={selectedType.id || ''}
          selected-field-str={selectedField.name}
          selected-variable-str={selectedVariable.name}
          selected-type-str={selectedType.name}
          onClick={displayStatistics}
          fullWidth
          id={displayButtonIdName}
          className={classes.displayStatsButton}
        >
          {!canRun
            ? t('points.displayStatisticButton')
            : t('points.displayExportButton')}
        </Button>
        <Button
          onClick={clearSelection}
          className={classes.clearButton}
          fullWidth
        >
          {t('points.clearSelectionButton')}
        </Button>
        <Spacer height={15} />
      </div>
    </>
  );
};

ProjectAnalyze.propTypes = {
  points: PropTypes.array.isRequired,
  selectedMarkerId: PropTypes.number,
  onSelectMarker: PropTypes.func.isRequired,
  fields: PropTypes.array.isRequired,
  statisticTypes: PropTypes.array.isRequired,
  variables: PropTypes.array.isRequired,
  changeField: PropTypes.func.isRequired,
  changeType: PropTypes.func.isRequired,
  changeVariables: PropTypes.func.isRequired,
  parameters: PropTypes.object.isRequired,
  statsIsLoading: PropTypes.bool.isRequired,
  clearSelection: PropTypes.func.isRequired,
  displayStatistics: PropTypes.func.isRequired,
  variablesIdName: PropTypes.string.isRequired,
  statisticsIdName: PropTypes.string.isRequired,
  fieldsIdName: PropTypes.string.isRequired,
  displayButtonIdName: PropTypes.string.isRequired,
};

export default memo(ProjectAnalyze);
