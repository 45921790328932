import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

import { useForm } from 'hooks/useForm';
import CheckboxIconButton from 'components/common/buttons/CheckboxIconButton';
import Empty, {
  SmallerTitleComponent,
} from 'components/common/EmptyContentFallback';

import { useStyles } from './styles';

/**
 * GrantAccessForCollaboratorForm component
 * Displays grant access for project to a collaborator form
 * @param { Array } projects - list of projects
 * @param { Function } updateProjectsCollaborators -  handler for adding a collaborator
 * @param { Array } projectsAvailableToUser -  array of projects ids. use if user(collaborator) is not new and added to some projects
 */

const GrantAccessForCollaboratorForm = ({
  projects,
  updateProjectsCollaborators,
  projectsAvailableToUser,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const initialValues = useMemo(
    () => ({
      selectedProjects: projectsAvailableToUser || [],
      changedProjects: [],
    }),
    [projectsAvailableToUser]
  );

  const { updateField, values } = useForm({
    initialValues,
  });
  const { selectedProjects } = values;

  const updateProjectAccess = useCallback(
    ({ currentTarget }) => {
      const projectId = currentTarget.getAttribute('data-project-id');
      const projectIdNumber = +projectId;
      updateField('selectedProjects', (prevProjects) =>
        prevProjects.includes(projectIdNumber)
          ? prevProjects.filter((item) => item !== projectIdNumber)
          : [...prevProjects, projectIdNumber]
      );
      if (projectsAvailableToUser) {
        updateField('changedProjects', (prevProjects) =>
          prevProjects.some(({ id }) => id === projectIdNumber)
            ? prevProjects.filter((item) => item.id !== projectIdNumber)
            : [
                ...prevProjects,
                {
                  id: projectIdNumber,
                  isChecked: !projectsAvailableToUser.includes(projectIdNumber),
                },
              ]
        );
      }
    },
    [updateField, projectsAvailableToUser]
  );

  const handleClick = useCallback(() => updateProjectsCollaborators(values), [
    updateProjectsCollaborators,
    values,
  ]);

  return (
    <form>
      <div className={classes.listWrapper}>
        {projects.length ? (
          <ul className={classes.list}>
            {projects.map(({ id, name, zone }) => (
              <li key={id} className={classes.listItem}>
                <div className={classes.listItemText}>
                  <Typography variant="h4" className={classes.listItemTitle}>
                    {name}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.listItemSubtitle}
                  >
                    {zone.name}
                  </Typography>
                </div>
                <div className={classes.listItemIcon}>
                  <CheckboxIconButton
                    isChecked={selectedProjects.includes(id)}
                    checkboxHandler={updateProjectAccess}
                    data-project-id={id}
                  />
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <Empty
            iconComponent={<ErrorOutlineOutlinedIcon />}
            iconSize={80}
            title={t('emptyState.noMatch.title')}
            titleComponent={SmallerTitleComponent}
          />
        )}
      </div>
      <div className={classes.modalButton}>
        <Button
          className={classes.saveButton}
          onClick={handleClick}
          data-submit
        >
          {t('buttons.save')}
        </Button>
      </div>
    </form>
  );
};

GrantAccessForCollaboratorForm.propTypes = {
  projects: PropTypes.array.isRequired,
  updateProjectsCollaborators: PropTypes.func.isRequired,
  projectsAvailableToUser: PropTypes.array,
};

export default React.memo(GrantAccessForCollaboratorForm);
