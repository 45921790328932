import {
  Typography,
  TextField,
  Button,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { memo, useMemo } from 'react';

import { useForm } from 'hooks/useForm';
import { clearProjectsError } from 'ducks/projects/actions';
import ZoneDataset from 'containers/zones/ZoneDataset';

import { useStyles } from './styles';
import { validationRules } from './validationRules';

/**
 * EditProjectForm component
 * Displays project creation form  in trial mode
 * @param {Array} commonErrors - array of server error
 * @param {Object} zoneId
 * @param {String} name - project name
 * @param {Function} onSuccess
 * @param {Function} onClose
 * @param {Function} onExpand
 */
const EditProjectForm = ({
  commonErrors = [],
  name = '',
  onClose,
  onSuccess,
  zoneId,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const initialValues = useMemo(() => ({ name }), [name]);
  const clearErrors = () => dispatch(clearProjectsError());

  const { handleSubmit, handleBlur, handleChange, errors } = useForm({
    initialValues,
    validationRules,
    onSuccess,
  });

  return (
    <form noValidate onSubmit={handleSubmit}>
      <div className={classes.row}>
        <Typography variant="body1" className={classes.fieldName}>
          {t('projects.create.labels.name')}
        </Typography>
        <div className={classes.field}>
          <TextField
            fullWidth
            name="name"
            size="medium"
            defaultValue={name}
            onBlur={handleBlur}
            onChange={handleChange}
            onFocus={clearErrors}
            variant="outlined"
            placeholder={t('projects.create.setProjectName')}
          />
          <FormHelperText error className={classes.helperText}>
            {errors.name}
          </FormHelperText>
        </div>
      </div>
      <div className={classes.commonErrors}>
        {commonErrors &&
          commonErrors.map((error) => (
            <FormHelperText key={error} error>
              {error}
            </FormHelperText>
          ))}
      </div>

      <Typography align="center" className={classes.dataValidationDescription}>
        {t('projects.create.dataValidationDescription')}
      </Typography>

      <ZoneDataset zoneId={zoneId} />

      <div className={classes.modalButton}>
        <Button onClick={onClose} className={classes.closeButton}>
          {t('buttons.close')}
        </Button>
        <Button type="submit" className={classes.saveButton} data-submit>
          {t('buttons.create')}
        </Button>
      </div>
    </form>
  );
};

EditProjectForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  zoneId: PropTypes.number.isRequired,
  commonErrors: PropTypes.array,
  name: PropTypes.string,
};

export default memo(EditProjectForm);
