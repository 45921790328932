import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  menu: {
    width: '100%',
    height: 60,
    minHeight: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
    backgroundColor: theme.palette.primary.main,
    listStyleType: 'none',
  },
  status: {
    position: 'absolute',
    left: 20,
    fontSize: 16,
  },
  item: {
    padding: 0,
  },
  link: {
    fontSize: 14,
    color: theme.palette.white.main,
    textTransform: 'uppercase',
    padding: '7px 14px 6px',
    transition: 'color 0.2s ease-in',
    '&:hover, &.active': {
      color: theme.palette.white.main,
      textDecoration: 'none',
      fontWeight: 'bold',
    },
    '&.active:before': {
      content: '""',
      position: 'absolute',
      left: 20,
      right: 20,
      bottom: 0,
      height: 3,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
