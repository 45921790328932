import { makeStyles } from '@material-ui/core/styles';
import { alpha } from '@material-ui/core/styles/colorManipulator';

export const useStyles = makeStyles(({ palette }) => ({
  collapseRoot: {
    marginBottom: 10,
    border: 'none',
    boxShadow: `0 1px 1px ${alpha(palette.tertiary.main, 0.05)}`,
  },
  collapseSummaryRoot: {
    padding: '0 15px',
    height: 40,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: palette.sinay.primary['40'],
    fontSize: 14,
    '&$collapseSummaryExpanded': {
      minHeight: 40,
    },
  },
  collapseSummaryContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '&$collapseSummaryExpanded': {
      margin: '12px 0',
    },
  },
  collapsePanel: {
    padding: 0,
    margin: 0,
    display: 'block',
  },
  collapseSummaryExpanded: {},
  iconTitle: {
    marginRight: 5,
    width: 20,
  },
}));
