import {
  put,
  fork,
  takeLatest,
  race,
  take,
  select,
  call,
  spawn,
} from 'redux-saga/effects';
import { generatePath } from 'react-router';
import { push, getLocation } from 'connected-react-router';
import i18next from 'i18next';

import { redirect } from 'helpers/common';
import { ROUTES } from 'constants/routes';
import { projectsActions, projectsTypes } from 'ducks/projects';
import { modalsActions } from 'ducks/modals';
import { userTypes, userActions } from 'ducks/user';
import { planningsActions, planningsTypes } from 'ducks/plannings';
import { STATUS_LOADING } from 'constants/common';
import {
  RESET_PASSWORD_SUCCESS_MODAL,
  GRANT_ACCESS_FOR_COLLABORATOR_MODAL,
  NOTIFICATION_MODAL,
  // GRANT_ACCESS_TO_PROJECT_MODAL,
  COMPARISON_AGAINST_SATELLITE_RESULT,
  PLANNING_SETTINGS_MODAL,
  UPLOAD_PLANNING_SUCCESS_MODAL,
  PLANNING_WAITING_MODAL,
  SIGNUP_SUCCESS_MODAL,
  PLANNING_SETTINGS_ERRORS_MODAL,
} from 'constants/modals';
import { USER_RESET_PASSWORD_REQUEST_SUCCESS } from 'ducks/user/types';
import { REQUEST_BUY_MAPS_SUCCESS } from 'ducks/stat2d/types';
import { FINISH_UPDATE_PROJECT_JOB } from 'ducks/projects/types';
import { selectIsFinishedProjectById } from 'ducks/projects/selectors';
import { requestPoint, requestPoints } from 'ducks/points/actions';
import {
  COMPUTE_SATELLITE_COMPARISON_SUCCESS,
  FINISH_UPDATE_SATELLITE_COMPARISON_JOB,
} from 'ducks/satelliteComparisons/types';
import {
  selectIsFinishedSatelliteComparisonById,
  selectSatelliteComparisonById,
} from 'ducks/satelliteComparisons/selectors';
import { preparePlanningTemplateForSettingsForm } from 'helpers/plannings';
import { selectPointById } from 'ducks/points/selectors';
import {
  extractParamsFromLocationSearch,
  extractTokenFromLocationHash,
} from 'helpers/hijack';
import {
  setHijackParams,
  getHijackParams,
  removeHijackParams,
} from 'services/hijackStorage';
import {
  AUTH_KEYCLOAK_SUCCESS,
  AUTH_KEYCLOAK_FAILED,
} from 'ducks/keycloak/types';
import {
  initKeycloak,
  impersonateUser,
  stopImpersonation,
} from 'ducks/keycloak/actions';
import { trackSignUp } from 'ducks/trackers/actions/authentification';

import errorHandlingWatcher from '../errorHandling/sagas';
import { selectPlanningsStatus } from '../plannings/selectors';

import { appHijackModeEnable, appInit } from './actions';
import {
  APP_HIJACK_EJECT,
  APP_INIT,
  CREATE_PLANNING_WAITING_REQUEST,
  CREATE_PROJECT_WITH_REDIRECT,
  CREATE_COMPUTED_PROJECT_WITH_REDIRECT,
  EDIT_USER_PROFILE_WAITING_REQUEST,
  INVITE_USER_WAITING_REQUEST,
  RESEND_USER_INVITATION_WAITING_REQUEST,
  REVOKE_USER_INVITATION_WAITING_REQUEST,
  UPDATE_PROJECT_COLLABORATORS_WAITING_REQUEST,
  UPLOAD_PLANNING_WAITING_REQUEST,
  USER_SIGNUP_WAITING_REQUEST,
} from './types';
import { selectAppIsInHijackMode } from './selectors';

export function* initAppWorker() {
  const location = yield select(getLocation);
  const { loginTo, releaseTo } = getHijackParams();

  let isAuth = false;
  // attempt to authentificate using "hijacked" user
  if (location.pathname === ROUTES.hijackLanding) {
    const [
      hijackAccessToken,
      hijackRefreshToken,
    ] = extractTokenFromLocationHash(location);
    yield put(impersonateUser(hijackAccessToken, hijackRefreshToken));
    const [success, failure] = yield race([
      take(AUTH_KEYCLOAK_SUCCESS),
      take(AUTH_KEYCLOAK_FAILED),
    ]);

    if (success && !failure) {
      const urlParams = extractParamsFromLocationSearch(location);
      const params = {
        loginTo: urlParams.loginTo ?? ROUTES.home,
        releaseTo: urlParams.releaseTo ?? ROUTES.login,
      };
      setHijackParams(params);
      yield put(appHijackModeEnable());
      isAuth = true;
    }
  } else if (loginTo !== null && releaseTo !== null) {
    // if we have stored a param,
    // this means that we are currently hijacking a user.
    yield put(appHijackModeEnable());
  }

  // "real" attempt to authentificate
  if (!isAuth) {
    // Init keycloak, and try to authentificate
    yield put(initKeycloak());
    // wait for success/failure
    const [success, failure] = yield race([
      take(AUTH_KEYCLOAK_SUCCESS),
      take(AUTH_KEYCLOAK_FAILED),
    ]);
    isAuth = success && !failure;
  }

  if (isAuth) {
    yield put(userActions.getCurrentUser());
    yield race([
      take(userTypes.FETCH_CURRENT_USER_SUCCESS),
      take(userTypes.USER_REQUEST_ERROR),
    ]);
  }
  yield put(userActions.getUserSettings());
  yield put(appInit());
}

export function* afterInitWorker() {
  const isInHijackMode = yield select(selectAppIsInHijackMode);
  if (isInHijackMode) {
    const { loginTo } = yield select(getHijackParams);
    if (loginTo !== null) {
      yield put(push(loginTo));
    }
    // yield put(startSessionCheck());
  }
}

function* appHijackEjectWorker() {
  const { releaseTo } = getHijackParams();
  removeHijackParams();
  yield put(stopImpersonation());
  if (releaseTo !== null) {
    redirect(releaseTo);
  }
}

export function* createProjectWithRedirectWorker(action) {
  const { isTrial, ...project } = action.project;

  yield put(
    !isTrial
      ? projectsActions.createProject(project)
      : projectsActions.createTrialProject(project)
  );

  const { error, success } = yield race({
    success: take([
      projectsTypes.CREATE_PROJECT_SUCCESS,
      projectsTypes.CREATE_TRIAL_PROJECT_SUCCESS,
    ]),
    error: take([
      projectsTypes.CREATE_PROJECT_ERROR,
      projectsTypes.CREATE_TRIAL_PROJECT_ERROR,
    ]),
  });

  if (!error) {
    yield put(modalsActions.closeAllModal());
    yield put(push(generatePath(ROUTES.project, success.project)));
    // yield put(
    //   modalsActions.showModal({
    //     modalType: GRANT_ACCESS_TO_PROJECT_MODAL,
    //     options: { projectId: success.project.id, name: success.project.name },
    //   })
    // );
  }
}

export function* createComputedProjectWithRedirectWorker(action) {
  const { isTrial, ...project } = action.project;

  yield put(projectsActions.createDemoProject(project));

  const { error, success } = yield race({
    success: take([projectsTypes.CREATE_DEMO_PROJECT_SUCCESS]),
    error: take([projectsTypes.CREATE_DEMO_PROJECT_ERROR]),
  });

  if (!error) {
    yield put(modalsActions.closeAllModal());
    yield put(push(generatePath(ROUTES.projectAnalyze, success.project)));
    // yield put(
    //   modalsActions.showModal({
    //     modalType: GRANT_ACCESS_TO_PROJECT_MODAL,
    //     options: { projectId: success.project.id, name: success.project.name },
    //   })
    // );
  }
}

/**
 * triggers invite user action, waits for success/error, when success, closes modal
 * @param {Object} data
 * @param {Number} modalId
 */
export function* inviteUserWaitingWorker({ data, modalId }) {
  yield put(userActions.inviteUserRequest(data));

  const { error, success } = yield race({
    success: take(userTypes.INVITE_USER_SUCCESS),
    error: take(userTypes.INVITE_USER_ERROR),
  });
  if (!error) {
    yield put(modalsActions.closeModal({ id: modalId }));
    yield put(
      modalsActions.showModal({
        modalType: GRANT_ACCESS_FOR_COLLABORATOR_MODAL,
        options: { collaboratorId: success.id, email: success.email },
      })
    );
  } else {
    yield put(modalsActions.closeModal({ id: modalId }));
    yield put(
      modalsActions.showModal({
        modalType: NOTIFICATION_MODAL,
        options: {
          type: 'error',
          message: error.error.msg,
        },
      })
    );
  }
}

function* appResetPasswordSuccessWorker() {
  yield put(
    modalsActions.showModal({ modalType: RESET_PASSWORD_SUCCESS_MODAL })
  );
}

/**
 * triggers update project collaborators action, waits for success/error, when success, closes modal
 * @param {Object} data
 * @param {Number} modalId
 * @param {Number} user
 */
export function* updateProjectCollaboratorsWaitingRequestWorker({
  data,
  modalId,
  user,
}) {
  yield put(projectsActions.updateProjectCollaboratorsRequest({ data, user }));

  const { error } = yield race({
    success: take(projectsTypes.UPDATE_PROJECT_COLLABORATORS_REQUEST_SUCCESS),
    error: take(projectsTypes.UPDATE_PROJECT_COLLABORATORS_REQUEST_ERROR),
  });

  if (!error) {
    yield put(modalsActions.closeModal({ id: modalId }));
  }
}

export function* editUserProfileWaitingRequestWorker({ values, modalId }) {
  yield put(userActions.editUserProfileRequest(values));

  const { error } = yield race({
    success: take(userTypes.EDIT_USER_PROFILE_REQUEST_SUCCESS),
    error: take(userTypes.EDIT_USER_PROFILE_REQUEST_ERROR),
  });

  if (!error) {
    yield put(modalsActions.closeModal({ id: modalId }));
  }
}

/**
 * triggers notification modal
 * @param {Object} zone
 */
function* appBuyMapsSuccessWorker() {
  yield put(
    modalsActions.showModal({
      modalType: NOTIFICATION_MODAL,
      options: {
        type: 'success',
        message: i18next.t('projects.buyMapsModal.successBought', {}),
      },
    })
  );
}

function* resendUserInvitationWaitingRequestWorker({ user, modalId }) {
  yield put(userActions.resendUserInvitationRequest(user.id));

  const { error } = yield race({
    success: take(userTypes.RESEND_USER_INVITATION_REQUEST_SUCCESS),
    error: take(userTypes.RESEND_USER_INVITATION_REQUEST_ERROR),
  });

  if (!error) {
    yield put(modalsActions.closeModal({ id: modalId }));
    // TODO show notice with success message and user email
  }
}

function* revokeUserInvitationWaitingRequestWorker({ user, modalId }) {
  yield put(userActions.revokeUserInvitationRequest(user.id));

  const { error } = yield race({
    success: take(userTypes.REVOKE_USER_INVITATION_REQUEST_SUCCESS),
    error: take(userTypes.REVOKE_USER_INVITATION_REQUEST_ERROR),
  });

  if (!error) {
    yield put(modalsActions.closeModal({ id: modalId }));
    // TODO show notice with success message and user email
  }
}

/**
 * Update points after finish computation
 * @param {Number} projectId
 */
function* updatePointsAfterComputationWorker({ projectId }) {
  const isFinishedProject = yield select(
    selectIsFinishedProjectById,
    projectId
  );

  if (isFinishedProject) {
    yield put(requestPoints({ project: projectId }));
  }
}

/**
 * Show success notification on compute comparison success
 */
function* showNotificationAfterSuccessComputeComparisonWorker() {
  yield put(
    modalsActions.showModal({
      modalType: NOTIFICATION_MODAL,
      options: {
        message: i18next.t('points.comparisonSuccess'),
        type: 'success',
      },
    })
  );
}

/**
 * Update point data in store and open result modal, after comparison
 */
function* closeModalAndUpdatePointAfterComparisonWorker({ comparisonId }) {
  const comparison = yield select(selectSatelliteComparisonById, comparisonId);
  const point = yield select(selectPointById, comparison.point);
  const isFinished = yield select(
    selectIsFinishedSatelliteComparisonById,
    comparisonId
  );

  if (isFinished) {
    yield put(requestPoint(comparison.point));
    yield put(modalsActions.closeAllModal());
    yield put(
      modalsActions.showModal({
        modalType: COMPARISON_AGAINST_SATELLITE_RESULT,
        options: { point },
      })
    );
  }
}

/**
 * Uploads planning computation template
 */
function* uploadPlanningWaitingRequestWorker({ projectId, file }) {
  yield put(planningsActions.uploadPlanning({ projectId, file }));

  const planningStatus = yield select(selectPlanningsStatus);
  const modal = modalsActions.showModal({
    modalType: PLANNING_WAITING_MODAL,
    options: { fileName: file.name },
  });
  if (planningStatus === STATUS_LOADING) {
    yield put(modal);
  }

  const { error, success } = yield race({
    success: take(planningsTypes.UPLOAD_PLANNING_SUCCESS),
    error: take(planningsTypes.UPLOAD_PLANNING_ERROR),
  });

  if (error) {
    yield put(modalsActions.closeModal({ id: modal.id }));
    return;
  }

  const errors = success.data?.errors;
  if (errors) {
    yield put(
      modalsActions.showModal({
        modalType: PLANNING_SETTINGS_ERRORS_MODAL,
        options: { errors },
      })
    );
    return;
  }

  const modalData = yield call(preparePlanningTemplateForSettingsForm, {
    planningTemplateData: success.data,
    projectId,
    file,
  });
  yield put(modalsActions.closeAllModal());
  yield put(
    modalsActions.showModal({
      modalType: PLANNING_SETTINGS_MODAL,
      options: modalData,
    })
  );
}

/**
 * Creates new planning
 */
function* createPlanningWaitingRequestWorker({
  projectId,
  user,
  planningData,
  file,
}) {
  yield put(
    planningsActions.createPlanning({ projectId, file, user, planningData })
  );

  const planningStatus = yield select(selectPlanningsStatus);
  const modal = modalsActions.showModal({
    modalType: PLANNING_WAITING_MODAL,
    options: { fileName: file.name },
  });
  if (planningStatus === STATUS_LOADING) {
    yield put(modalsActions.closeAllModal());
    yield put(modal);
  }

  const { error } = yield race({
    success: take(planningsTypes.CREATE_PLANNING_SUCCESS),
    error: take(planningsTypes.CREATE_PLANNING_ERROR),
  });

  if (!error) {
    yield put(modalsActions.closeAllModal());
    yield put(
      modalsActions.showModal({
        modalType: UPLOAD_PLANNING_SUCCESS_MODAL,
      })
    );
  }
}

/**
 * triggers sign up user action, waits for success/error, when success, shows modal
 * @param {Object} values
 */
export function* userSignUpWaitingWorker({ values }) {
  yield put(userActions.userSignup(values));

  const { error } = yield race({
    success: take(userTypes.USER_SIGNUP_REQUEST_SUCCESS),
    error: take(userTypes.USER_REQUEST_ERROR),
  });
  if (!error) {
    yield put(trackSignUp(values));
    yield put(modalsActions.showModal({ modalType: SIGNUP_SUCCESS_MODAL }));
  }
}

export default function*() {
  yield fork(initAppWorker);
  yield spawn(errorHandlingWatcher);
  yield takeLatest(APP_HIJACK_EJECT, appHijackEjectWorker);
  yield takeLatest(APP_INIT, afterInitWorker);
  yield takeLatest(
    CREATE_PROJECT_WITH_REDIRECT,
    createProjectWithRedirectWorker
  );
  yield takeLatest(
    CREATE_COMPUTED_PROJECT_WITH_REDIRECT,
    createComputedProjectWithRedirectWorker
  );
  yield takeLatest(INVITE_USER_WAITING_REQUEST, inviteUserWaitingWorker);
  yield takeLatest(
    USER_RESET_PASSWORD_REQUEST_SUCCESS,
    appResetPasswordSuccessWorker
  );
  yield takeLatest(
    UPDATE_PROJECT_COLLABORATORS_WAITING_REQUEST,
    updateProjectCollaboratorsWaitingRequestWorker
  );
  yield takeLatest(
    EDIT_USER_PROFILE_WAITING_REQUEST,
    editUserProfileWaitingRequestWorker
  );
  yield takeLatest(
    RESEND_USER_INVITATION_WAITING_REQUEST,
    resendUserInvitationWaitingRequestWorker
  );
  yield takeLatest(
    REVOKE_USER_INVITATION_WAITING_REQUEST,
    revokeUserInvitationWaitingRequestWorker
  );
  yield takeLatest(REQUEST_BUY_MAPS_SUCCESS, appBuyMapsSuccessWorker);
  yield takeLatest(
    FINISH_UPDATE_PROJECT_JOB,
    updatePointsAfterComputationWorker
  );
  yield takeLatest(
    COMPUTE_SATELLITE_COMPARISON_SUCCESS,
    showNotificationAfterSuccessComputeComparisonWorker
  );
  yield takeLatest(
    FINISH_UPDATE_SATELLITE_COMPARISON_JOB,
    closeModalAndUpdatePointAfterComparisonWorker
  );
  yield takeLatest(
    UPLOAD_PLANNING_WAITING_REQUEST,
    uploadPlanningWaitingRequestWorker
  );
  yield takeLatest(
    CREATE_PLANNING_WAITING_REQUEST,
    createPlanningWaitingRequestWorker
  );
  yield takeLatest(USER_SIGNUP_WAITING_REQUEST, userSignUpWaitingWorker);
}
