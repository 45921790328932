import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(() => ({
  title: {
    margin: '10px',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
  percentile: {
    textAlign: 'center',
  },
  containerTitle: {
    marginTop: '20px',
  },
  selectionContainer: {
    width: '700px',
  },
  addButton: {
    color: '#439c6d',
    transform: 'scale(1)',
  },
  autocomplete: {
    width: '100%',
  },
  launchButton: {
    ...theme.buttons.outlined.secondary,
  },
  backButton: {
    ...theme.buttons.elevated.neutral,
  },
}));
