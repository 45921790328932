import {
  BUOY_ICON,
  BUOY_CURRENT_ICON,
  BUOY_WAVE_ICON,
  BUOY_WIND_ICON,
  POINT_NOT_READY_ICON,
  POINT_NOT_READY_SATELLITE_ICON,
  POINT_NOT_READY_SELECTED_ICON,
  POINT_NOT_READY_SELECTED_SATELLITE_ICON,
  POINT_READY_ICON,
  POINT_READY_SATELLITE_ICON,
  POINT_READY_SELECTED_ICON,
  POINT_READY_SELECTED_SATELLITE_ICON,
} from 'constants/icons';

/**
 * Icon settings for markers
 * @typedef {{popupAnchor: number[], iconAnchor: number[], iconSize: number[], iconUrl}} MarkerIcon
 */

/**
 * Blue icon with cross and satellite
 * @type {MarkerIcon}
 */
export const pointNotReadySatellite = {
  iconUrl: POINT_NOT_READY_SATELLITE_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 45],
  popupAnchor: [0, 0],
};

/**
 * Blue icon with cross
 * @type {MarkerIcon}
 */
export const pointNotReady = {
  iconUrl: POINT_NOT_READY_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 37],
  popupAnchor: [0, 0],
};

/**
 * Green icon with mark
 * @type {MarkerIcon}
 */
export const pointReady = {
  iconUrl: POINT_READY_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 37],
  popupAnchor: [0, 0],
};

/**
 * Green icon with mark and satellite
 * @type {MarkerIcon}
 */
export const pointReadySatellite = {
  iconUrl: POINT_READY_SATELLITE_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 45],
  popupAnchor: [0, 0],
};

/**
 * Red icon with cross
 * @type {MarkerIcon}
 */
export const pointReadySelected = {
  iconUrl: POINT_READY_SELECTED_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 37],
  popupAnchor: [0, 0],
};

/**
 * Red icon with mark and satellite
 * @type {MarkerIcon}
 */
export const pointReadySelectedSatellite = {
  iconUrl: POINT_READY_SELECTED_SATELLITE_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 45],
  popupAnchor: [0, 0],
};

/**
 * Red icon with cross
 * @type {MarkerIcon}
 */
export const pointNotReadySelected = {
  iconUrl: POINT_NOT_READY_SELECTED_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 37],
  popupAnchor: [0, 0],
};

/**
 * Red icon with cross and satellite
 * @type {MarkerIcon}
 */
export const pointNotReadySelectedSatellite = {
  iconUrl: POINT_NOT_READY_SELECTED_SATELLITE_ICON,
  iconSize: [20, 28],
  iconAnchor: [15, 45],
  popupAnchor: [0, 0],
};

/**
 * Red icon buoy
 * @type {MarkerIcon}
 */
export const buoy = {
  iconUrl: BUOY_ICON,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [0, 0],
};

/**
 * Wave buoy icon
 * @type {MarkerIcon}
 */
export const buoyWave = {
  iconUrl: BUOY_WAVE_ICON,
  iconSize: [28, 31],
  iconAnchor: [14, 31],
  popupAnchor: [0, 0],
};

/**
 * Wind buoy icon
 * @type {MarkerIcon}
 */
export const buoyWind = {
  iconUrl: BUOY_WIND_ICON,
  iconSize: [28, 31],
  iconAnchor: [14, 31],
  popupAnchor: [0, 0],
};

/**
 * Current buoy icon
 * @type {MarkerIcon}
 */
export const buoyCurrent = {
  iconUrl: BUOY_CURRENT_ICON,
  iconSize: [28, 31],
  iconAnchor: [14, 31],
  popupAnchor: [0, 0],
};
