import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import NativeLink from 'components/common/NativeLink';
import { ROUTES } from 'constants/routes';

import { useStyles } from './styles';

/**
 * Controls for comparison modal
 * @param {Function} onClose
 * @param {String} downloadLink
 * @param {Boolean} disableDownload
 * @param {Object} classes
 * @return {jsx}
 */
const ComparisonModalControls = ({ onClose, onClick, disableDownload }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Grid container justifyContent="space-between">
      <NativeLink to={ROUTES.helpValidation}>
        <Button className={classes.helpButton}>{t('buttons.help')}</Button>
      </NativeLink>
      {!disableDownload && onClick && (
        <Button onClick={onClick} className={classes.downloadButton}>
          {t('buttons.download')}
        </Button>
      )}
      <Button onClick={onClose} className={classes.closeButton}>
        {t('buttons.close')}
      </Button>
    </Grid>
  );
};

ComparisonModalControls.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  disableDownload: PropTypes.bool,
};

export default memo(ComparisonModalControls);
