import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& input': {
      padding: 10,
    },
    margin: '10px 50px 0 50px',
  },
  checkboxRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalButton: {
    display: 'flex',
    justifyContent: 'center',
  },
  helperText: {
    minHeight: 10,
    maxWidth: 250,
    wordWrap: 'break-word',
  },
  fieldName: {
    marginRight: 30,
    marginBottom: 10,
    fontWeight: 700,
  },
  fullWidth: {
    width: '100%',
  },
  formText: {
    textAlign: 'center',
    marginBottom: 15,
    fontSize: 12,
  },
  iconHelp: {
    fontSize: 16,
    color: theme.palette.primary.main,
  },
  sendButton: {
    ...theme.buttons.outlined.secondary,
  },
}));
