import { makeStyles } from '@material-ui/core/styles';

import { theme } from 'common/theme';

export const useStyles = makeStyles(({ palette }) => ({
  title: {
    marginRight: '20px',
    color: palette.sinay.primary['40'],
    padding: '15px 0px',
    fontSize: 24,
    fontWeight: 'bold',
  },
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
  },
  statisticsContainer: {
    width: '95%',
    backgroundColor: palette.background.paper,
  },
  tabs: {
    borderRight: `1px solid ${palette.divider}`,
  },
  pointTab: {
    minWidth: 0,
  },
  operationTitle: {
    margin: '20px',
    fontSize: 20,
    fontWeight: 'bold',
  },
  operationBoat: {
    fontSize: 16,
    color: palette.grey.middle,
  },
  graphTitle: {
    fontWeight: 'bold',
  },
  appBar: {
    flexDirection: 'row',
    background: 'none',
  },
  operationsDashboard: {
    background: 'white',
    display: 'flex',
    flexDirection: 'row',
    gap: 21,
    flexWrap: 'wrap',
  },
  launchButton: {
    ...theme.buttons.outlined.secondary,
  },
  exportButton: {
    ...theme.buttons.elevated.neutral,
  },
}));
