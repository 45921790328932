import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import PublishIcon from '@material-ui/icons/Publish';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { useDispatch } from 'react-redux';

import UploadButton from 'components/common/buttons/UploadButton';
import { mapUploadGeometry } from 'ducks/trackers/actions/map';

import GeometriesList from '../GeometriesList';

import { useStyles } from './styles';

/**
 * ManageGeometries component
 * Displays the manage geometries button
 * @param {Array} geometries
 * @param {Function} onUpload - handler for adding a geometries
 * @param {Function} onDelete - handler for deleting geometry
 * @param {Function} onCancel
 */
const ManageGeometries = ({
  onUpload,
  onCancel,
  onDelete,
  onUpdateGeometry,
  onUpdateLayer,
  geometries,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { t } = useTranslation();

  const handleGtm = () => {
    dispatch(mapUploadGeometry());
  };

  return (
    <div className={classes.container}>
      {geometries.length ? (
        <GeometriesList
          geometries={geometries}
          onDelete={onDelete}
          onUpdateGeometry={onUpdateGeometry}
          onUpdateLayer={onUpdateLayer}
        />
      ) : (
        <Typography
          gutterBottom
          variant="body1"
          className={classes.noUploadsMessage}
        >
          {t('projects.geometry.noUploads')}
        </Typography>
      )}
      <Typography gutterBottom variant="body2" className={classes.formText}>
        {t('projects.geometry.supportText')}
      </Typography>
      <div className={classes.buttonsGroup}>
        <Button className={classes.cancelButton} onClick={onCancel}>
          {t('buttons.cancel')}
        </Button>
        <UploadButton
          startIcon={<PublishIcon />}
          onUpload={onUpload}
          onClick={handleGtm}
          className={classes.uploadButton}
        >
          {t('projects.geometry.labels.upload')}&nbsp;*
        </UploadButton>
      </div>
    </div>
  );
};

ManageGeometries.propTypes = {
  geometries: PropTypes.array.isRequired,
  onUpload: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default memo(ManageGeometries);
