import React, { memo } from 'react';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import shareIcon from 'assets/icons/share.svg';

import { useStyles } from './styles';

/**
 * Button wti person icon for shared project
 * @param {Object} props
 * @param {String} className
 * @returns {jsx}
 */
const ShareProjectButton = ({ className, ...props }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const shareIconUrl = shareIcon;

  return (
    <div className={classes.buttonContainer}>
      <Button
        aria-label={t('alts.inviteButton')}
        className={classes.floatingButton}
        classes={{ label: classes.label }}
        {...props}
      >
        <img src={shareIconUrl} alt="Share" className={classes.shareIcon} />
        <span className={classes.textLabel}>{t('projects.share')}</span>
      </Button>
    </div>
  );
};

export default memo(ShareProjectButton);
